import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../components/layout'
import { Link } from 'gatsby'

import wheelGIF from '../assets/images/wheelGIF.gif'
import wheelGIF2 from '../assets/images/wheelGIF2.gif'
import scoreMap from '../assets/images/scoremap.png'

const Sozai = props => (
  <Layout>
    <Helmet>
      <title>About</title>
      <meta name="description" content="Project - Ecommerce" />
    </Helmet>

    <div id="main" className="alt">
      <section id="one">
        <div className="inner">
          <header className="major">
            <h1>Wheel of Prosperity</h1>
          </header>
          <tbody>
            <tr>
              <th>Type</th>
              <th>Stack</th>
              <th>Code</th>
              <th>Live</th>
            </tr>
            <tr>
              <td>Team</td>
              <td>React</td>
              <td class="ecommerce">
                <a
                  href="https://github.com/taekimura/Wheel-of-prosperity"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Repository
                </a>
              </td>
              <td class="Decore__Bold-sc-1tbn2mo-3 gicxJS">
                <a
                  href="https://wheel-of-prosperity-portfolio.netlify.app/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  View Site
                </a>
              </td>
            </tr>
            <tr>
              <td></td>
              <td>Redux</td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td></td>
              <td>Firebase</td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
          </tbody>
          <br />
          <p>
            Golden Heart Wisdom is a platform of neutrality through which it’s
            possible to welcome CLARITY, FLUIDITY, and LIGHTNESS in all areas of
            life, and this, with ease and simplicity.
            <br />
            They offer several online resources such as memberships,
            downloadable products, and some events that are available and
            accessible to everyone, worldwide, to assist humanity. Also, from
            time to time, retreats and live events are offered, to assist
            humanity even further.
            <br /> I worked on the project as a final project at Cornerstone
            International Community College and met the client through{' '}
            <a
              href="https://www.riipen.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Riipen
            </a>
            . This website was used in a retreat to be held in November 2020.
          </p>
          <br />
          <br />
          <h4>HOW IT WORKS</h4>
          <br />
          <p>
            The project consists of the creation of a 24-question quiz, based on
            an answer ranging from 0 to 10. <br />
            The compilation of the answers is grouped as follows:
            <li>0</li>
            <li>1 - 80</li>
            <li>81 - 120</li>
            <li>121 - 240</li>
            The result will be accompanied by a "wheel of prosperity" visual
            that will show the curve of the results. The wheel has 12 different
            coloured spokes and the 24 questions are grouped in pairs to give an
            average for each spoke. The spoke is at 10 in the center... and at 0
            when it touches the outer spoke of the wheel.
            <br />
            <br />* This is provided to specific program participants, and all
            questions on{' '}
            <a
              href="https://wheel-of-prosperity-portfolio.netlify.app/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Demo
            </a>{' '}
            are changed for confidentiality.
          </p>
          <br />
          <span className="image main">
            <img src={scoreMap} alt="" />
          </span>
          <span className="image main">
            <img src={wheelGIF} alt="" />
          </span>
          <span className="image main">
            <img src={wheelGIF2} alt="" />
          </span>

          <ul className="actions2">
            <li>
              <Link to="/#projects">Back</Link>
            </li>
          </ul>
          <br />
        </div>
      </section>
    </div>
  </Layout>
)

export default Sozai
